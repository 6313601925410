<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <InvoicesTable filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import InvoicesTable from './elements/invoicesTable.vue'

export default {
  name: 'Invoices',
  components: { InvoicesTable }
}
</script>
